import React from "react"
import MasterLayout from "../../components/MasterLayout"
import Container from "../../components/Container"

const Articles = () => {
  return (
    <MasterLayout>
      <Container>Welcome to yolokuder</Container>
    </MasterLayout>
  )
}
export default Articles
